<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de local</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.local.nome.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="local.nome" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.local.nome.required">Campo obrigatório</div>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cep">CEP</label>
								<input v-model="cep" type="text" class="form-control" name="cep" id="cep" placeholder="99.999-999" v-mask="'#####-###'">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="logradouro">Logradouro</label>
								<input v-model="local.logradouro" type="text" class="form-control" name="logradouro" id="logradouro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="numero">Número</label>
								<input v-model="local.numero" type="text" ref="numero" class="form-control" name="numero" id="numero">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="complemento">Complemento</label>
								<input v-model="local.complemento" type="text" class="form-control" name="complemento" id="complemento">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="bairro">Bairro</label>
								<input v-model="local.bairro" type="text" class="form-control" name="bairro" id="bairro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cidade">Cidade</label>
								<input v-model="local.cidade" type="text" class="form-control" name="cidade" id="cidade">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="estado">Estado</label>
								<input v-model="local.estado" type="text" class="form-control" name="estado" id="estado" v-mask="'AA'">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import locaisTreinamentosRepository from './../../../services/api/locaisTreinamentosRepository'
import { required } from 'vuelidate/lib/validators'
import {GetAddress} from './../../../services/cep.service'
export default {
	validations: {
    local: {
			nome: {
				required
			}
		}
  },
	data () {
		return {
			local: {
        nome: ''
			},
			cep: null
		}
	},
	watch: {
    cep: {
      handler () {
        if (this.cep && this.cep !== null && this.cep.length == 9) {
					this.buscaCep()
				}
      }
    }
	},
	created () {
		if (this.$route.query.id) {
			this.buscaLocal(this.$route.query.id)
		}
	},
	methods: {
		buscaLocal (idLocal) {
			locaisTreinamentosRepository.buscaLocal(idLocal).then(response => {
				this.local = response.data['data']
				this.cep = this.local.cep
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'LocaisTreinamentos' })
		},
		buscaCep: function () {
			GetAddress(this.cep).then(endereco => {
				this.local.cep = this.cep
				this.local.logradouro = endereco.logradouro
				this.local.bairro = endereco.bairro
				this.local.cidade = endereco.cidade
				this.local.estado = endereco.estado
				this.$refs.numero.focus()
				// resolve, mas nao deveria precisar
				this.$forceUpdate()
			})
		},
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.local.id) {
					locaisTreinamentosRepository.atualizarLocal(this.local.id, this.local).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				} else {
					locaisTreinamentosRepository.salvarLocal(this.local).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Local cadastrado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				}
			}
		}
	}
}
</script>

<style>

</style>